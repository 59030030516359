<template>
  <div class="header">
    <b-row class="d-flex justify-content-between header mb-2">
      <b-col>
        <h1 class="serverName text-primary ml-2">
          {{ `${serverInfo.name.toUpperCase() || 'Type a Server Name'}` }}
        </h1>
      </b-col>
    </b-row>
    <b-row class="ml-2">
      {{
        `Identifier: ${serverInfo.identifier} - Created at: ${serverInfo.created_at}`
      }}
    </b-row>

    <hr class="my-2" />
    <validation-observer ref="serverValidation">
      <b-form @submit.prevent>
        <b-col cols="12">
          <b-row>
            <b-col xl="4" lg="4" md="4" sm="12" cols="12">
              <b-form-group label="Server Name" label-for="server-name">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ServerIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="server-name"
                      placeholder="Server Name"
                      v-model="serverInfo.name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col xl="4" lg="4" md="4" sm="12" cols="12">
              <b-form-group label="Server Username" label-for="server-username">
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="server-username"
                      placeholder="Server Username"
                      v-model="serverInfo.username"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col xl="4" lg="4" md="4" sm="12" cols="12">
              <b-form-group label="Password" label-for="server-password">
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="KeyIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="server-password"
                      placeholder="Server Password"
                      v-model="serverInfo.password"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col xl="6" lg="6" md="6" sm="12" cols="12">
              <b-form-group label="Host" label-for="server-host">
                <validation-provider
                  #default="{ errors }"
                  name="host"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DatabaseIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="server-host"
                      placeholder="Server Host"
                      v-model="serverInfo.host"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col xl="6" lg="6" md="6" sm="12" cols="12">
              <b-form-group label="Type" label-for="server-type">
                <b-input-group class="input-group-merge">
                  <!-- <b-input-group-prepend is-text>
										<feather-icon icon="PowerIcon"/>
									</b-input-group-prepend> -->

                  <b-form-input
                    id="server-host"
                    placeholder="Server Type"
                    :value="serverInfo.type"
                    readonly
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col xl="4" lg="4" md="5" sm="12" cols="12">
              <b-form-group label="Status" label-for="server-status">
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <b-form-select id="server-status" v-model="serverInfo.status">
                    <b-form-select-option
                      v-for="(option, index) in selectStatus"
                      :key="index"
                      v-model="option.id"
                    >
                      {{ option.label }}
                    </b-form-select-option>
                    <template #first>
                      <b-form-select-option v-model="undefined" disabled
                        >-- Please select --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col xl="4" lg="4" md="5" sm="12" cols="12">
              <b-form-group label="Weigth" label-for="server-weigth">
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="AnchorIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="server-weight"
                      placeholder="Server Weight"
                      v-model="serverInfo.weight"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col xl="2" lg="2" md="1" sm="12" cols="12">
              <b-form-group
                label="Public"
                label-for="server-public"
                class="ml-2"
              >
                <b-form-checkbox
                  id="server-public"
                  :class="`custom-control-success`"
                  class="mt-1"
                  v-model="serverInfo.is_public"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col xl="6" lg="6" md="6" sm="12" cols="12">
              <b-form-group label="Port" label-for="server-port">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SlidersIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="server-port"
										type="number"
                    placeholder="Server Port"
                    v-model="serverInfo.port"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col xl="6" lg="6" md="6" sm="12" cols="12">
              <b-form-group label="Path" label-for="server-path">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="FolderIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="server-path"
                    placeholder="Server Path"
                    v-model="serverInfo.path"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Server Query" label-for="server-query">
                <b-input-group class="input-group-merge">
                  <!-- <b-input-group-prepend is-text>
                    <feather-icon icon="ListIcon" />
                  </b-input-group-prepend> -->
                  <b-form-textarea
                    id="server-query"
                    placeholder="Server Query"
                    rows="7"
                    v-model="serverInfo.query_param"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="handleSubmit"
                variant="primary"
                class="mr-1"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { required, email } from '@validations'

import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BInputGroupAppend,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      serverInfo: [],
      selectStatus: [],
			required, 
			email,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.fetchServerById();
      this.fetchStatus();
    },
    fetchServerById() {
      this.$store
        .dispatch("getServerById", this.$route.params)
        .then((response) => {
          console.log(response);

          this.serverInfo = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchStatus() {
      this.$store
        .dispatch("fetchStatus")
        .then((response) => {
          console.log(response);

          this.selectStatus = response.items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSubmit() {
      this.$refs.serverValidation.validate().then((success) => {
        if (success) {
          this.$store.dispatch('updateServer', this.serverInfo)
						.then((response) => {
						this.$router.push({ name: 'info-server', params: { serverId: this.serverInfo.id }})
							.then(() => {
								console.log('Passou tudo falta o toast pae')
							})
					}).catch((error) => {
						console.log(error)
					})
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>